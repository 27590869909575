<template>
  <div class="tab-pane active" id="rcs-stats">
    <div class="card-body">
      <div class="table-responsive">
        <div
          v-if="!usage.products && fetched"
          class="text-center table-placeholder"
        >
          <br />
          <i class="fas fa-tachometer-alt font-size-80"></i>
          <h5 class="card-title m-t-20">Nenhum gasto encontrado</h5>
        </div>
        <table v-if="fetched" class="table table-borderless">
          <thead class="">
            <tr>
              <th>{{ $tc('generic-str.lbl-product', 1) }}</th>
              <th>{{ $t('generic-str.lbl-quantity') }}</th>
              <th>{{ $t('generic-str.lbl-cost') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="font-weight-500">Total</td>
              <td class="font-weight-500">{{ usage.totalCount }}</td>
              <td class="font-weight-500">
                {{ usage.totalPrice | currency4D }}
              </td>
            </tr>
            <tr v-for="product in usage.products" :key="product.name">
              <td>
                {{ product.description }}
              </td>
              <td>{{ product.count }}</td>
              <td>{{ product.price | currency4D }}</td>
            </tr>
          </tbody>
        </table>
        <div v-else class="qt-block-ui" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import Tab from '@/directives/Tab';
import UsageService from '@/services/usage.service';

export default {
  name: 'RCSReport',
  directives: {
    Tab,
  },
  props: ['dateGte', 'dateLte'],
  data() {
    return {
      fetchTimeout: 0,
      fetched: true,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        startDate: moment().format('01/MM/YYYY'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      usage: [],
      form: {
        category: 'sms',
        date: {
          gte: moment().format('YYYY-MM-01'),
          lte: moment().format('YYYY-MM-DD'),
        },
      },
    };
  },
  /* computed: {
    dateGte() {
      this.preFetchUsage(1);
      return this.$parent.form.date.gte;
    },
    dateLte() {
      this.preFetchUsage(1);
      return this.$parent.form.date.lte;
    },
  }, */
  watch: {
    dateGte: {
      handler(value) {
        this.preFetchUsage(1);
      },
      deep: true,
    },
    dateLte: {
      handler(value) {
        this.preFetchUsage(1);
      },
      deep: true,
    },
  },
  mounted() {
    this.fetchUsage(1);
  },
  methods: {
    reload() {
      this.fetchUsage(1);
    },
    preFetchUsage(page) {
      clearTimeout(this.fetchTimeout);
      this.fetchTimeout = setTimeout(() => this.fetchUsage(page), 10);
    },
    fetchUsage(page) {
      this.fetched = false;
      UsageService.getAllUsage({
        category: 'sms',
        date: {
          gte: this.dateGte,
          lte: this.dateLte,
        },
      }).then((response) => {
        this.usage = response;
        this.fetched = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.table-responsive {
  min-height: 150px;
}
</style>
